<template>
  <CtaButton class="Small" v-bind="{ text, action, icon }" @click="$attrs.onClick" />
</template>

<script>
import CtaButton from './CtaButton.vue'

export default {
  components: {
    CtaButton,
  },
  props: {
    text: String,
    action: String,
    icon: String,
  },
}
</script>

<style scoped>
.Small.CtaButton {
  padding: var(--spacing-3) var(--spacing-6);
  font-size: var(--font-size-2);
}
</style>
