<template>
  <component :is="tagName" class="Container">
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
  },
}
</script>

<style scoped>
.Container {
  padding-left: var(--gutter-width);
  padding-right: var(--gutter-width);
}
</style>
