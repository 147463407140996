import type { App } from 'vue';

import settings from '../settings.json';
import pageActions from './vue-plugins/page-actions.js';
import sharedInseegoComponents from './vue-plugins/shared-inseego-components.js';

export default (app: App) => {
  app.provide('sanityConfig', settings.sanity);
  app.use(pageActions);
  app.use(sharedInseegoComponents);
};
