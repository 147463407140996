import { PortableText } from '@portabletext/vue'

import Container from '../vue-components/Container.vue'
import CtaButton from '../vue-components/button-styles/CtaButton.vue'
import CtaLink from '../vue-components/button-styles/CtaLink.vue'
import LargeCtaButton from '../vue-components/button-styles/LargeCtaButton.vue'
import SmallCtaButton from '../vue-components/button-styles/SmallCtaButton.vue'
import UnstyledCta from '../vue-components/button-styles/UnstyledCta.vue'
import SanityImage from '../vue-components/sanity-image-components/SanityImage.vue'
import SanityImageBackground from '../vue-components/sanity-image-components/SanityImageBackground.vue'
import SanityPictureSource from '../vue-components/sanity-image-components/SanityPictureSource.vue'
import SanityVideo from '../vue-components/sanity-image-components/SanityVideo.vue'

export default function ({ component }) {
  component('Container', Container)
  component('CtaButton', CtaButton)
  component('CtaLink', CtaLink)
  component('LargeCtaButton', LargeCtaButton)
  component('SmallCtaButton', SmallCtaButton)
  component('UnstyledCta', UnstyledCta)
  component('PortableText', PortableText)
  component('SanityImage', SanityImage)
  component('SanityImageBackground', SanityImageBackground)
  component('SanityPictureSource', SanityPictureSource)
  component('SanityVideo', SanityVideo)
}
