<template>
  <source :srcset="imageUrl">
</template>

<script>
import { sanityImageUrl } from '~/lib/imageUrls.js'

export default {
  props: {
    asset: {
      type: Object,
      default: null,
      validator: (value) => value._type === 'reference',
    },
  },
  inject: ['sanityConfig'],
  computed: {
    imageUrl() {
      const assetId = this.asset?._ref
      return assetId ? sanityImageUrl(this.sanityConfig, assetId, { auto: 'format' }) : false
    },
  }
}
</script>
