<template>
  <CtaButton class="Large" v-bind="{ text, action, icon }" @click="$attrs.onClick" />
</template>

<script>
import CtaButton from './CtaButton.vue'

export default {
  components: {
    CtaButton,
  },
  props: {
    text: String,
    action: String,
    icon: String,
  },
}
</script>

<style scoped>
.Large.CtaButton {
  padding: var(--spacing-5) var(--spacing-7);
  font-size: var(--font-size-4);
}
</style>
