<template>
  <video class="SanityVideo" :poster="posterImageUrl" :src="videoUrl"></video>
</template>

<script>
import { sanityFileUrl, sanityImageUrl } from '~/lib/imageUrls.js'

export default {
  props: {
    posterImage: {
      type: Object,
      default: null,
    },
    videoFile: {
      type: Object,
      default: null,
    },
  },
  inject: ['sanityConfig'],
  computed: {
    posterImageUrl() {
      const imageId = this.posterImage?.asset?._ref
      if (imageId) {
        const options = {
          w: '1280',
          auto: 'format',
        }
        return sanityImageUrl(this.sanityConfig, imageId, options)
      }
    },
    videoUrl() {
      const assetId = this.videoFile?.asset?._ref
      if (assetId) {
        return sanityFileUrl(this.sanityConfig, assetId)
      }
    },
  },
}
</script>
