export default function ({ config }) {

  const callbacks = {};

  const dispatch = function (name, ...args) {
    callbacks[name]?.(...args);
  };

  const register = function (name, callback) {
    callbacks[name] = callback;
  };

  const unregister = function (name) {
    delete callbacks[name];
  };

  config.globalProperties.$pageActions = {
    dispatch,
    register,
    unregister,
  };
}
